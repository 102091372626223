import React from 'react';
import { Link } from 'gatsby';


export const Cta = () => {
  return (
    <div className="text-light py-5" style={{ background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="hero mt-5">
          <h1 className="text-center mb-5 mx-auto" style={{ maxWidth: '800px', fontSize: '3rem' }}>
            Download CDBootstrap PRO Library + A Premium Bootstrap 5 Admin Template
          </h1>
          <div className="hero__buttons text-center mt-5">
            <Link href="#pricing" className="btn1 hero__btn btn__inverse mx-auto">
              <span className="btn__text">Get CDBootstrap Pro</span>
            </Link>
          </div>

          {/* <p className="text-center mt-2">View full <Link to="/windframe">documentation here</Link></p> */}
        </div>
      </div>
    </div>
  );
};
