import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/AboutSamplePro/React/VideoSection';
import { Hero } from '../../components/AboutSamplePro/React/Hero';
import { Hero3 } from '../../components/AboutSamplePro/React/Hero3';
import { Hero2 } from '../../components/AboutSamplePro/React/Hero2';
import { Hero4 } from '../../components/AboutSamplePro/React/Hero4';
import { Feature } from '../../components/AboutSamplePro/React/Feature';
import { Showcase } from '../../components/AboutSamplePro/React/Showcase';
import { PagesShowcase } from '../../components/AboutSamplePro/React/PagesShowcase';
import { DocsPreview } from '../../components/AboutSamplePro/React/DocsPreview';
import { Pricing } from '../../components/AboutSamplePro/React/Pricing';
import { Cta } from '../../components/AboutSamplePro/React/Cta';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import FAQ from '../../components/AboutSamplePro/React/Faq';
import '../../styles/scss/style.scss'

const AdminContrastPro = () => {

  return (
    <AppContext>
      <Helmet>
        <title>Bootstrap 5 Admin Template - Bootstrap 5 Contrast PRO Library and Template </title>
        <meta name="title" content="Bootstrap 5 Admin Template - Bootstrap 5 Contrast PRO Library and Template" />
        <meta
          name="description"
          content="Bootstrap 5 admin templates and Bootstrap 5 UI Kit for building modern responsive pages with well crafter ui components"
        />
        <meta
          property="og:title"
          content="Bootstrap 5 Admin Template - Bootstrap 5 Contrast PRO Library and Template "
        />

        <meta
          property="og:description"
          content="Bootstrap 5 admin templates and Bootstrap 5 UI Kit for building modern responsive pages with well crafter ui components"
        />
        <meta
          property="twitter:title"
          content="Bootstrap 5 Admin Template - Bootstrap 5 Contrast PRO Library and Template"
        />
        <meta
          property="twitter:description"
          content="Bootstrap 5 admin templates and Bootstrap 5 UI Kit for building modern responsive pages with well crafter ui components"
        />
        <link rel="canonical" href="https://www.devwares.com/product/bootstrap-contrast-pro/" />
        <meta property="og:url" content="https://www.devwares.com/product/bootstrap-contrast-pro/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <VideoSection />
            <div className="mt-5">
              <FeaturedIn />
            </div>
            <Feature />
            <Hero3 />
            <Hero2 />
            <Hero4 />
            <PagesShowcase />
            <DocsPreview />
            <Showcase />
            <Pricing />
            <FAQ />
            <Cta />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminContrastPro;
